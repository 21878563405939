<div class="ama-banner ama-banner--{{ color }}"
     [ngStyle]="{ 'background-image': hasBannerImage() ? 'url('+bannerImageUrl+')' : '' }"
     [ngClass]="{ 'ama-banner__image--cover': hasBannerImage() }">
  <div class="ama-banner__left-icon"
       *ngIf="icon && icon.length > 0">
    <ama-icon [size]="'15px'"
              [name]="icon"></ama-icon>
  </div>
  <div class="ama-banner__banner-text">
    <ng-content></ng-content>
  </div>

  <div class="ama-banner__banner-actions">
    <button class="ama-banner__button ama-banner__button--{{ color }} ama-banner__button--{{ buttonStyle }}"
            (click)="bannerButtonClick()"
            [attr.aria-label]="buttonText">
      <div class="ama-banner__button--text"
           [innerHTML]="buttonText"></div>
      <ama-icon class="ama-banner__icon ama-banner__button-icon"
                size="14px"
                [name]="buttonIcon"
                *ngIf="buttonIcon && buttonIcon.length > 1"></ama-icon>
    </button>
    <button class="ama-banner__close-button"
            (click)="closeBanner()"
            *ngIf="showCloseButton"
            aria-label="close">
      <ama-icon [name]="'close'"
                [size]="'1em'"></ama-icon>
    </button>
  </div>
</div>
